<template>
  <tr>
    <td>{{ item.season.id }}</td>
    <td v-if="(item.product.slug).includes('hors_club')">Licence HORS CLUB</td>
    <td v-else>{{ NomStructure }}</td>
    <td>
      {{ NomTypeLicence }}
      <span v-if="item.nonPracticing">(Non pratiquant)</span>
    </td>
    <td v-if="item.product.slug === 'licence_decouverte'">
      {{ item.licenceOptions[0].option.label }} ({{ new Date(item.dateStart).toLocaleDateString('fr-FR', {
        dateStyle: 'short',
      }) }})
    </td>
    <td v-else>
      <AssuranceChipItem
        v-for="(assu, index) in currentAssurances"
        :key="index"
        :context="{ ...context, assurance: assu }"
        @chipsSelected="openDialog"
        :deletable="true"
      />
    </td>
    <YesNo
      id="yesno-dialog"
      title="CONFIRMATION"
      text="Souhaitez-vous vraiment supprimer l'option d'assurance?"
      maxWidth="360"
      :showDialog="confirmDialog"
      @answerYes="deleteAssurance"
      @answerNo="confirmDialog = false"
    />
    <td v-if="item.product.slug !== 'licence_decouverte'"
      @click="
        $emit('print-licence', {
          name: `licence-${item.user.ctPrenom}-${item.user.ctNom}-saison${item.season.id}`,
          ID_Saison: item.season.id,
        })
      "
      class="position-relative"
    >
      <v-icon v-if="item.season.id <= currentSaison.id" class="pointer"
        >mdi-file</v-icon
      >
    </td>
    <td v-else>-</td>

    <td v-if="item.product.slug !== 'licence_decouverte'"
      @click="
        $emit('print-attestation', {
          name: `attestation-${item.user.ctPrenom}-${item.user.ctNom}-saison${item.season.id}`,
          ID_Saison: item.season.id,
        })
      "
      class="position-relative"
    >
      <v-icon v-if="item.season.id <= currentSaison.id" class="pointer"
        >mdi-file</v-icon
      >
    </td>
    <td v-else>-</td>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </tr>
</template>

<script>
import YesNo from "@/components/utils/modals/yes-no.vue";
import AssuranceChipItem from "@/components/Assurances/Assurances__ChipItem.vue";
import { mapActions, mapGetters } from "vuex";
import { success_notification } from "@/plugins/notifications";
import { bus } from "@/main";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import {formatFluxFromLineHistoriqueLicenceDeleteOptionsAssurance, insertFlux} from "@/plugins/fluxService";
import { OPTION_TYPE_ASSURANCE, OPTION_TYPE_IJ, OPTION_TYPE_OPTION_ASSURANCE, OPTION_TYPE_OPTION_LICENCE_HC } from "@/plugins/productService";

export default {
  data: () => ({
    mounted: false,
    licences: [],
    confirmDialog: false,
    payload: null,
    assurances: null,
    essai: "test components",
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    ffmeTarifs: null,
  }),
  components: { AssuranceChipItem, YesNo, PrintFacture },

  async created() {
    await this.initRolesAndPermissions();
    await this.getData();
    return (this.mounted = true);
  },

  props: {
    context: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("utilisateurs", [
      "deleteOptionAssurance",
      "getUtilisateursLicenceAssurance",
      "createFluxFinancierDetail",
    ]),
    ...mapActions("auth", ["initRolesAndPermissions"]),
    ...mapActions("basics", [
      "getLicenceById",
      "getListTypeLicence",
      "getTarifsFFME",
      "insertTarifs",
      "getTarifsByID",
    ]),
    async getData() {
      this.ffmeTarifs = await this.getTarifsFFME({
        ID_Saison: 2022,
      });
    },
    openDialog(payload) {
      this.payload = payload;
      this.confirmDialog = true;
    },
    async deleteAssurance() {
      let response = await this.deleteOptionAssurance({
        id: this.payload.assurance?.id,
      });
      // const tarif = this.ffmeTarifs.find(
      //   (ta) =>
      //     ta.LIST_TarifCategorie?.AssuranceType?.id ===
      //     this.payload.ID_Assurance
      // );
      // const fluxFinancierDetail = {
      //   ID_Saison: this.currentSaison.id,
      //   ID_Tarif: this.payload.ID_Tarif,
      //   EST_Debit: false,
      //   NomFluxFinancierDetail: `Annulation ${tarif.NomTarif}- Saison ${this.context.ID_Saison} - ${this.context.user.UTI_Utilisateur?.CT_Nom} ${this.context.user.UTI_Utilisateur?.CT_Prenom}`,
      //   ID_Utilisateur: this.context.ID_Utilisateur,
      //   MontantHT: tarif.MontantUnitaire,
      //   MontantTTC: tarif.MontantUnitaire,
      //   MontantTVA: 0,
      //   FIN_CodeAnalytique: 0,
      //   FIN_CodeCompta: 0,
      // };
      // const fluxPrincipalUser = {
      //   ID_FluxFinancierDebit: 0,
      //   ID_Saison: this.context.ID_Saison,
      //   ID_Structure: this.context.ID_Structure,
      //   // ID_Structure: 1318,
      //   ID_Traitement: 1,
      //   ID_Utilisateur: this.context.ID_Utilisateur,
      //   MontantHT: tarif.MontantUnitaire,
      //   MontantTTC: tarif.MontantUnitaire,
      //   MontantTVA: 0,
      //   EST_Debit: false,
      //   NomFluxFinancier: `Annulation ${tarif.NomTarif}- Saison ${this.context.ID_Saison} - ${this.context.user.UTI_Utilisateur?.CT_Nom} ${this.context.user.UTI_Utilisateur?.CT_Prenom}`,
      //   EST_Reglement: false,
      //   // ID_PI_Stripe: payload ? payload.ID_PI_Stripe : "",
      //   MKP_FluxFinancierDetail: {
      //     data: fluxFinancierDetail,
      //     on_conflict: {
      //       constraint: "MKP_FluxFinancierDetail_pkey",
      //       update_columns: ["ID_Tarif", "MontantHT", "MontantTTC"],
      //     },
      //   },
      // };
      //
      // const insertedFlux = await this.createFluxFinancierDetail({
      //   flux: { ...fluxPrincipalUser },
      // });
      this.assurances = [...this.currentAssurances];

      if (response) {
        await insertFlux(formatFluxFromLineHistoriqueLicenceDeleteOptionsAssurance(this.item,this.currentAssurances,this.payload.assurance));
        success_notification("Suppression avec succès");
        bus.$emit("reloadAssuranceItem");
        bus.$emit("updateCM");

        this.confirmDialog = false;

        // this.printFacture(insertedFlux);
      }
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
  },
  computed: {
    ...mapGetters("saisons", ["currentSaison", "selectedSaison"]),
    item() {
      console.log('itemm', this.context.item)
      return this.context.item;
    },

    NomStructure() {
      if (!this.item.structure) return "";
      return this.item.structure.nomStructure;
    },

    NomTypeLicence() {
      if (!this.item && this.item.product) return "";
      return this.item && this.item.product.label;
    },

    currentAssurances() {
      if (!this.item.licenceOptions) return [];
      let options = this.item.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE);
      let optionsHC = this.item.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
      let complementaire = this.item.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_IJ);
      let assurance = this.item.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_ASSURANCE )[0] ?? null;

      return [assurance, ...options, ...optionsHC, ...complementaire];
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
