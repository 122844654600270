export const waitingDocument = 'waiting_document';
export const loisir = 'loisir';
export const competition = 'competition';
export const qs = 'qs';

export const docTypeToStatusRelation = {
  5: loisir,
  9: competition,
  60: qs,
};
