<template>
  <v-row :key="context.ID_Utilisateur">
    <v-col cols="12" v-if="withTitle">
      <h4 v-if="context.fromModal">Historique des licences :</h4>
      <h4 v-else>Historique de mes licences:</h4>
    </v-col>

    <v-col cols="12" v-if="mounted" class="px-0">
      <v-simple-table fixed-header height="600px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Saison</th>
              <th class="text-left">Nom du club</th>
              <th class="text-left">Type de licence</th>
              <th class="text-left">Options</th>
              <th class="text-left">Licence</th>
              <th class="text-left">Attestation</th>
            </tr>
          </thead>
          <tbody>
            <LineHistoriqueLicence
              v-for="(item, index) in licences"
              :key="index"
              :context="{ ...context, item: item }"
              @print-licence="(data) => printLicence(data)"
              @print-attestation="(data) => printAttestation(data)"
            />
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <PrintLicence ref="licence" :print="print"></PrintLicence>
    <PrintAttestation ref="attestation" :print="print"></PrintAttestation>
    <!-- <LicenceAttestation  :context="{...context, ID_Saison: printSaison ||context.ID_Saison}" :key="printSaison" ref="licence-attestation" @allow-print="(val) => isPrintDisabled = val"></LicenceAttestation> -->
  </v-row>
</template>

<script>
import LineHistoriqueLicence from "@/components/Assurances/Assurances__LineHistoriqueLicence.vue";

import PrintLicence from "@/modules/MesLicences/PrintLicence.vue";
import PrintAttestation from "@/modules/MesLicences/PrintAttestation.vue";
import { mapActions, mapGetters } from "vuex";

import { error_notification } from "@/plugins/notifications";
import { waitingDocument, loisir, competition, qs } from "@/constants/licenceStatus";
import {OPTION_TYPE_ASSURANCE, OPTION_TYPE_IJ, OPTION_TYPE_OPTION_ASSURANCE, OPTION_TYPE_OPTION_LICENCE_HC} from "@/plugins/productService";
export default {
  components: { LineHistoriqueLicence, PrintAttestation, PrintLicence },

  data: () => ({
    mounted: false,
    licences: [],
    // isPrintDisabled: false,
    printSaison: null,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
      template: null,
    },
  }),

  computed: {
    ...mapGetters("saisons", ["currentSaison", "SelectedSaison"]),

    ...mapGetters("structure", ["currentStructure"]),
    isPrintDisabled() {
      if (this.print.cm === waitingDocument) {
        return { disabled: true, message: "Pas de document médical valide" };
      }
      if (!this.print.licence)
        return { disabled: true, message: "Pas de licence valide" };
      // disable print if no licence active on currentSaison
      if (!this.print.licence.dateEnd)
        return {
          disabled: true,
          message: "Pas de fin de validité de la licence",
        };
      if (!this.print.licence.dateStart)
        return {
          disabled: true,
          message: "Pas de date de validation de la licence",
        };
      return { disabled: false };
    },

    nextSaison() {
      return this.currentSaison.id + 1;
    },
  },

  async created() {
    await this.setData();
    this.$emit("setTitle", "Licences et assurances");
    this.mounted = true;
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getLicenceUtilisateur",
      "getUtilisateurFullData",
      "getUtilisateur",
    ]),
    ...mapActions("basics", [
      "getTemplatesBySlug",
    ]),
    ...mapActions("user", ["getInfoSante"]),
    async setData() {
      this.licences = await this.getLicenceUtilisateur({
        user: this.context.ID_Utilisateur,
        season: !this.context.all ? this.context.ID_Saison : undefined,
        'groups[1]': 'licence_history:read',
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });

      this.licences.sort((a, b) => b.season.id - a.season.id);
    },
    async printAttestation({ name, ID_Saison }) {
      this.print.ID_Saison = ID_Saison;
      this.print.options = [];
      await this.getPrintData();
      if (this.isPrintDisabled.disabled)
        return error_notification(this.isPrintDisabled.message);
      this.$refs.attestation.printAttestation(name);
    },
    async printLicence({ name, ID_Saison }) {
      this.print.ID_Saison = ID_Saison;
      await this.getPrintData();
      if (this.isPrintDisabled.disabled)
        return error_notification(this.isPrintDisabled.message);
      this.$refs["licence"].printLicence(name);
    },
    async getPrintData() {
      // Fix for print licence options were duplicated
      this.print.options = [];
      this.print.licence = this.licences.find((lic) => lic.season.id == this.print.ID_Saison);
      this.print.options = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE || opt.option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
      this.print.complementaire = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_IJ);
      this.print.assurance = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_ASSURANCE )[0] ?? null;
      this.print.cm = [waitingDocument, loisir, competition, qs].includes(this.print.licence.status) ? this.print.licence.status : await this.getInfoSante({ ID_Utilisateur: this.context.ID_Utilisateur });
      this.print.user = await this.getUtilisateur({
        ID_Utilisateur: this.context.ID_Utilisateur,
      });
      let payload = {
        ID_Saison: this.print.ID_Saison,
        SlugNomTemplate: "Licence_template",
      };
      this.print.template = await this.getTemplatesBySlug(payload);
      this.print = { ...this.print };
    },
  },

  props: {
    context: {
      type: Object,
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    context: async function () {
      this.licences = await this.getLicenceUtilisateur({
        user: this.context.ID_Utilisateur,
        season: this.context.ID_Saison,
        'groups[1]': 'licence_history:read',
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });
    },
  },
};
</script>
